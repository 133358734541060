
import { defineComponent, onMounted, ref } from "vue";
import dayjs from "dayjs";
import { upload } from "@/api/web";
import { completeInstruction } from "@/api/task";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";

interface IFileItem {
  id: string,
  status: string,
  message: string,
  content: string,
}

export default defineComponent({
  components: {},
  setup() {
    const complateTime = ref("");
    const message = ref("");

    onMounted(() => {
      complateTime.value = dayjs().format("YYYY-MM-DD HH:mm");
    });

    const files = ref<IFileItem[]>([]);

    // 加载状态
    const loadFileStatus = async (file: any) => {
      let formData = new FormData();
      formData.append("dir", "wp-file");
      formData.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      const res = await upload(formData);
      if ((res as any).resp_code === 0) {
        file.id = (res as any).datas.id;
        file.content = (res as any).datas.url;
        file.status = "";
        file.message = "";
      } else {
        file.status = "fail";
        file.message = "上传失败";
      }
    };

    // 读取文件上传到服务器
    const afterRead = async (file: any) => {
      if (file instanceof Array) {
        file.forEach(async (item) => {
          loadFileStatus(item);
        });
      } else {
        loadFileStatus(file);
      }
    };
    // 文件大小限制 图片 2M 视频 10M
    const onOversize = (file: any) => {
      console.log("onOversize", file);
    };

    const route = useRoute();
    const router = useRouter();

    const onSubmit = async () => {
      const params = {
        receiveCompleteTime: complateTime.value && `${complateTime.value}:00`,
        receiveReq: message.value,
        fileId: files.value.map((item) => item.id).join(","),
        receiveId: (route.query as any).receiveId || "",
        instructionId: (route.query as any).instructionId || "",
      };

      const res = await completeInstruction(params);
      if ((res as any).resp_code === 0) {
        Toast({
          type: "success",
          message: (res as any).resp_msg,
        });
        router.replace({
          name: "task",
        });
      }
    };

    return {
      message,
      files,
      complateTime,
      afterRead,
      onOversize,
      onSubmit,
    };
  },
});
