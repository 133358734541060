<template>
  <div class="task-complate">
    <div class="content">
      <van-form>
        <van-cell-group>
          <van-cell title="完成日期" :value="complateTime" />
        </van-cell-group>

        <div class="instruction-req-header">完成依据</div>

        <van-field
          v-model="message"
          rows="5"
          autosize
          label=""
          type="textarea"
          maxlength="200"
          placeholder="请输入完成依据"
          show-word-limit
        />

        <van-field required name="uploader" label="上传附件">
          <template #input>
            <van-uploader
              :max-count="5"
              accept="image/*, video/*"
              multiple
              :after-read="afterRead"
              @oversize="onOversize"
              v-model="files"
            />
          </template>
        </van-field>
      </van-form>
    </div>
    <div class="footer">
      <van-button @click="onSubmit" color="#467FFE" block round hairline
        >确认已完成</van-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import dayjs from "dayjs";
import { upload } from "@/api/web";
import { completeInstruction } from "@/api/task";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";

interface IFileItem {
  id: string,
  status: string,
  message: string,
  content: string,
}

export default defineComponent({
  components: {},
  setup() {
    const complateTime = ref("");
    const message = ref("");

    onMounted(() => {
      complateTime.value = dayjs().format("YYYY-MM-DD HH:mm");
    });

    const files = ref<IFileItem[]>([]);

    // 加载状态
    const loadFileStatus = async (file: any) => {
      let formData = new FormData();
      formData.append("dir", "wp-file");
      formData.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      const res = await upload(formData);
      if ((res as any).resp_code === 0) {
        file.id = (res as any).datas.id;
        file.content = (res as any).datas.url;
        file.status = "";
        file.message = "";
      } else {
        file.status = "fail";
        file.message = "上传失败";
      }
    };

    // 读取文件上传到服务器
    const afterRead = async (file: any) => {
      if (file instanceof Array) {
        file.forEach(async (item) => {
          loadFileStatus(item);
        });
      } else {
        loadFileStatus(file);
      }
    };
    // 文件大小限制 图片 2M 视频 10M
    const onOversize = (file: any) => {
      console.log("onOversize", file);
    };

    const route = useRoute();
    const router = useRouter();

    const onSubmit = async () => {
      const params = {
        receiveCompleteTime: complateTime.value && `${complateTime.value}:00`,
        receiveReq: message.value,
        fileId: files.value.map((item) => item.id).join(","),
        receiveId: (route.query as any).receiveId || "",
        instructionId: (route.query as any).instructionId || "",
      };

      const res = await completeInstruction(params);
      if ((res as any).resp_code === 0) {
        Toast({
          type: "success",
          message: (res as any).resp_msg,
        });
        router.replace({
          name: "task",
        });
      }
    };

    return {
      message,
      files,
      complateTime,
      afterRead,
      onOversize,
      onSubmit,
    };
  },
});
</script>

<style scoped lang="scss">
.task-complate {
  display: flex;
  flex-direction: column;
  background: $BACKGROUND_COLOR;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  overflow: hidden;
  .content {
    flex: 1;
    height: 0;
    overflow: auto;
    .instruction-req-header {
      color: $BASE_FONT_COLOR;
      font-weight: bold;
      font-size: $BASE_FONT_SIZE;
      padding: 16px;
    }
  }
  .footer {
    padding: 16px;
  }
}
</style>
